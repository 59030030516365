import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/subscriptions",
    name: "Subscriptions",
    meta: { title: "Subscriptions" },
    component: () => import(/* webpackChunkName: "Subscriptions List" */ "@/Modules/Subscription/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Subscription",
        meta: { title: "New Subscription" }
      },
      {
        path: "edit/:id",
        name: "Edit Subscription (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
  // {
  //   path: "/subscriptions/details/:id",
  //   name: "Subscription Details",
  //   component: () => import(/* webpackChunkName: "Subscription Details" */ "@/Modules/Subscription/Views/Details/Details"),
  //   children: [
  //     {
  //       path: "edit",
  //       name: "Edit Subscription (Details)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // }
]
