export default {
  user: {
    views: {
      list: {
        header: {
          title: "Uporabniki",
          search_placeholder: "Iskanje...",
          add_button: "Dodaj uporabnika"
        },
        table: {
          title: "Naslov",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          author: "Avtor",
          last_modified: "Nazadnje spremenjeno",
          active: "Aktiven"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabnikov",
          edit_button: "Uredi uporabnika",
          toggle: {
            title: "Aktiven",
            activate: "aktivacija",
            deactivate: "deaktivacija",
            description: "Tu lahko {action} uporabnika"
          }
        },
        main: {
          active: "Aktiven",
          inactive: "Neaktiven",
          overview: {
            author: "Avtor",
            created_at: "Ustvarjen",
            updated_at: "Posodobljen",
            last_modified: "Nazadnje spremenjen"
          }
        },
        resetPassword: {
          title: "Spremeni geslo",
          subtitle: "Varnost in avtentikacija",
          form: {
            currentPassword: "Trenutno geslo",
            newPassword: "Novo geslo",
            changePassword: "Spremeni geslo",
            passwordRule: "Geslo mora biti dolgo vsaj 6 znakov in mora vsebovati črko, številko in simbol"
          },
          feedback: {
            succcess: "Uspešno ste spremenili uporabniško geslo.",
            changeItAgain: "spremenite znova?"
          }
        },
        users_list: {
          title: "Dodani uporabniki",
          add_user: "Dodaj uporabnika",
          table: {
            name: "Ime",
            data_is_missing: "Tabela je prazna"
          }
        }
      }
    },
    popup: {
      title: "Nov uporabnik",
      fields: {
        name: "Polno ime",
        username: "Uporabniško ime",
        email: "Email"
      },
      add_button: "Dodaj uporabnika",
      update_button: "Posodobi uporabnika"
    },
    prompts: {
      delete: {
        title: "Izbriši {name}",
        message: "Izbrisali boste {name}. Ali želite nadaljevati s tem dejanjem?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uporabnik {name} ustvarjen",
      updated: "Uporabnik {name} posodobljen",
      deleted: "Uporabnik {name} izbrisan",
      not_found: "Določenega uporabnika ni mogoče najti"
    }
  },
  permissions: {
    title: "Dovoljenja",
    add_button: "Dodaj dovoljenja",
    table: {
      columns: {
        permission: "Dovoljenje",
        module: "Modul"
      },
      remove_button: "Odstrani dovoljenje"
    },
    empty_notice: "Ni dovoljenj za {name}.",
    add_drawer: {
      title: "Dodajte dovoljenja za {name}",
      search_placeholder: "Iskanje...",
      permissions_count: "{count} izbranih",
      table: {
        columns: {
          permission: "Dovoljenje",
          module: "Modul"
        }
      },
      cancel_button: "Prekliči",
      confirm_button: "Dodaj izbrana dovoljenja",
      notify: {
        success: "{count} dodanih dovoljenj na {name}",
        error: "Med dodajanjem dovoljenj na {name} je prišlo do napake"
      }
    }
  },
  access_drawer: {
    title: "Upravljanje uporabnikov",
    search: "Iskanje...",
    tag: "Število uporabnikov ",
    table: {
      name: "Ime"
    },
    cancel_button: "Cancel",
    save_button: "Shrani"
  }
}
