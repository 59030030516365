import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/licensing-users",
    name: "Users",
    meta: { title: "Users" },
    component: () => import(/* webpackChunkName: "Users List" */ "@/Modules/LicensingUser/Views/List"),
    children: [
      // {
      //   path: "new",
      //   name: "New User",
      //   meta: { title: "New User" }
      // },
      // {
      //   path: "edit/:id",
      //   name: "Edit User (List)"
      // }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/licensing-users/details/:id",
    name: "User Details",
    component: () => import(/* webpackChunkName: "User Details" */ "@/Modules/LicensingUser/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit User (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
