export default {
  views: {
    list: {
      header: {
        title: "Naročnine",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj naročnino"
      },
      table: {
        stripe_subscription_id: "ID Stripe naročnine",
        period: "Obdobje",
        current_period_start: "Začetek trenutnega obdobja",
        next_occurrence: "Naslednji pojav",
        licensing_user: "Uporabnik licence",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        tenant: "CRM podjetja"
      }
    },
    details: {
      header: {
        back_button: "Seznam naročnin",
        edit_button: "Uredi naročnino",
        toggle: {
          title: "Aktiven",
          activate: "aktivirajte",
          deactivate: "deaktivirajte",
          description: "Tu lahko {action} naročnino"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        }
      }
    }
  },
  popup: {
    title: "Nova naročnina",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj naročnino",
    update_button: "Posodobi naročnino"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Naročnina {title} ustvarjena",
    updated: "Naročnina {title} posodobljena",
    deleted: "Naročnina {title} je bila izbrisana",
    not_found: "Navedene naročnine ni mogoče najti"
  }
}
