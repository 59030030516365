export default {
  user: {
    views: {
      list: {
        header: {
          title: "Users",
          search_placeholder: "Search ...",
          add_button: "Add user"
        },
        table: {
          name: "Name",
          email: "Email",
          company: "Company",
          created_at: "Created at",
          updated_at: "Updated at",
          author: "Author",
          last_modified: "Last modified",
          active: "Active",
          tenant: "Tenant"
        }
      },
      details: {
        header: {
          back_button: "Users list",
          edit_button: "Edit user",
          toggle: {
            title: "Active",
            activate: "activate",
            deactivate: "deactivate",
            description: "You can {action} user here"
          }
        },
        main: {
          active: "Active",
          inactive: "Inactive",
          overview: {
            author: "Author",
            created_at: "Created at",
            updated_at: "Updated at",
            last_modified: "Last modified",
            email: "Email",
            phone: "Phone",
            stripe_id: "Stripe ID",
            vat_number: "VAT Number",
            address: "Address",
            city: "City",
            postal: "Postal",
            country: "Country",
            company: "Company"
          }
        },
        resetPassword: {
          title: "Change Password",
          subtitle: "Security and authentication",
          form: {
            currentPassword: "Current password",
            newPassword: "New password",
            changePassword: "Change password",
            passwordRule: "Password has to be at least 6 characters long and contain a letter, a number and a symbol"
          },
          feedback: {
            succcess: "You have successfully changed users password.",
            changeItAgain: "change it again?"
          }
        },
        users_list: {
          title: "Attached users",
          add_user: "Add user",
          table: {
            name: "Name",
            data_is_missing: "Table is empty"
          }
        }
      }
    },
    popup: {
      title: "New User",
      fields: {
        name: "Full name",
        username: "Username",
        email: "Email"
      },
      add_button: "Add user",
      update_button: "Update user"
    },
    prompts: {
      delete: {
        title: "Delete {name}",
        message: "You're about to delete {name}. Would you like to proceed with this action?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    },
    notify: {
      stored: "User {name} created",
      updated: "User {name} updated",
      deleted: "User {name} was deleted",
      not_found: "Unable to find specified user"
    }
  },
  permissions: {
    title: "Permissions",
    add_button: "Add permissions",
    table: {
      columns: {
        permission: "Permission",
        module: "Module"
      },
      remove_button: "Remove permission"
    },
    empty_notice: "There are no permissions for {name}.",
    add_drawer: {
      title: "Add permissions to {name}",
      search_placeholder: "Search ...",
      permissions_count: "{count} selected",
      table: {
        columns: {
          permission: "Permission",
          module: "Module"
        }
      },
      cancel_button: "Cancel",
      confirm_button: "Add selected permissions",
      notify: {
        success: "{count} permissions added to {name}",
        error: "An error occured while adding permissions to {name}"
      }
    }
  },
  access_drawer: {
    title: "User management",
    search: "Search...",
    tag: "Number of users ",
    table: {
      name: "Name"
    },
    cancel_button: "Canel",
    save_button: "Save"
  }
}
