export default {
  views: {
    list: {
      header: {
        title: "Subscriptions",
        search_placeholder: "Search ...",
        add_button: "Add subscription"
      },
      table: {
        stripe_subscription_id: "Stripe subscription ID",
        period: "Period",
        current_period_start: "Current period start",
        next_occurrence: "Next occurence",
        licensing_user: "Licensing user",
        created_at: "Created at",
        updated_at: "Updated at",
        active: "Active",
        tenant: "Tenant"
      }
    },
    details: {
      header: {
        back_button: "Subscriptions list",
        edit_button: "Edit subscription",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} subscription here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Subscription",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add subscription",
    update_button: "Update subscription"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Subscription {title} created",
    updated: "Subscription {title} updated",
    deleted: "Subscription {title} was deleted",
    not_found: "Unable to find specified subscription"
  }
}
